/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.RobloxSafety {
  position: relative;
  overflow: hidden;
  background: #f9f6ef;
  padding-left: 15rem;
  padding-right: 15rem;
  min-height: 70rem;
}
@media (max-width: 767px) {
  .RobloxSafety {
    min-height: auto;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.RobloxSafety > .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .RobloxSafety > .bottom {
    display: none;
  }
}
.RobloxSafety > .cloudRightTop {
  position: absolute;
  top: 15rem;
  right: -8rem;
}
@media (max-width: 767px) {
  .RobloxSafety > .cloudRightTop {
    display: none;
  }
}
.RobloxSafety > .cloudRightBottom {
  position: absolute;
  top: 35rem;
  right: 0;
}
@media (max-width: 767px) {
  .RobloxSafety > .cloudRightBottom {
    display: none;
  }
}
.RobloxSafety > .maxWidthContentWrapper > .safetyImg {
  position: absolute;
  bottom: 0;
  right: 5rem;
  width: 40%;
}
@media (max-width: 767px) {
  .RobloxSafety > .maxWidthContentWrapper > .safetyImg {
    position: relative;
    bottom: auto;
    right: auto;
    width: 100%;
    margin-top: 5rem;
  }
}
.RobloxSafety > .maxWidthContentWrapper > .content {
  width: 50%;
}
@media (max-width: 767px) {
  .RobloxSafety > .maxWidthContentWrapper > .content {
    width: 100%;
  }
}
.RobloxSafety > .maxWidthContentWrapper > .content > .title {
  margin-top: 10rem;
  margin-bottom: 4rem;
  font-size: 5rem;
  color: #745a9e;
  font-weight: 600;
}
.RobloxSafety > .maxWidthContentWrapper > .content > .description {
  margin-bottom: 4rem;
  font-size: 1.8rem;
  color: #534468;
  font-weight: 600;
}
