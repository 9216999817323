/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.RobloxLandingPage .HowItWorks {
  background-color: #f9f6ef;
}
.RobloxLandingPage .ProductOverview {
  background: #f9f6ef;
}
.RobloxLandingPage .GenericLandingAboveFold {
  background: #ff486b;
}
.RobloxLandingPage .GenericLandingAboveFold .left > .title > .roblox {
  color: #ffdc4a;
}
.RobloxLandingPage .PaymentPlans {
  background: #f2eee5;
}
.RobloxLandingPage .PaymentPlans .bottomInfo {
  background: #f9f6ef;
}
@media (max-width: 767px) {
}
.RobloxLandingPage .aboveFold {
  padding: 15vh 8rem 20rem;
  overflow: hidden;
  position: relative;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold {
    padding: 2rem 2rem 12rem;
    text-align: center;
    height: auto;
  }
}
.RobloxLandingPage .aboveFold .title {
  color: #745a9e;
  font-weight: 600;
  font-size: 4rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold .title {
    font-size: 3rem;
  }
}
.RobloxLandingPage .aboveFold .subtitle {
  color: #745a9e;
  font-weight: 600;
  font-size: 2rem;
  margin-top: 1.5rem;
  max-width: 40rem;
}
.RobloxLandingPage .aboveFold .left {
  float: left;
  width: 50%;
  padding: 1rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold .left {
    width: 100%;
  }
}
.RobloxLandingPage .aboveFold .right {
  float: right;
  width: 50%;
  padding: 1rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold .right {
    width: 100%;
  }
}
.RobloxLandingPage .aboveFold .landingImage {
  margin-top: 1.5rem;
}
.RobloxLandingPage .aboveFold .watchVideoDesktop {
  margin-top: 2.5rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold .watchVideoDesktop {
    display: none;
  }
}
.RobloxLandingPage .aboveFold .watchVideoMobile {
  display: none;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold .watchVideoMobile {
    display: inline-block;
    margin-top: -8rem;
  }
}
.RobloxLandingPage .aboveFold > .pipeImage {
  position: absolute;
  bottom: 0;
  left: 35%;
}
@media (max-width: 991px) {
  .RobloxLandingPage .aboveFold > .pipeImage {
    display: none;
  }
}
.RobloxLandingPage .aboveFold .animationFrame {
  position: absolute;
  right: 0;
  bottom: -2rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold .animationFrame .desktop {
    display: none;
  }
}
.RobloxLandingPage .aboveFold .animationFrame .mobile {
  display: none;
}
@media (max-width: 767px) {
  .RobloxLandingPage .aboveFold .animationFrame .mobile {
    display: block;
  }
}
.RobloxLandingPage .ProductOverview {
  background: #f9f6ef;
}
.RobloxLandingPage .coreFeatures {
  width: 100%;
  padding: 0 16rem;
  background: #aae053;
  overflow: hidden;
  position: relative;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures {
    display: none;
  }
}
@media (max-width: 1199px) {
  .RobloxLandingPage .coreFeatures {
    padding: 20px 40px;
  }
}
.RobloxLandingPage .coreFeatures h2 {
  font-weight: 600;
  color: #fff;
  font-size: 5.2rem;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures h2 {
    text-align: center;
    font-size: 4.5rem;
  }
}
.RobloxLandingPage .coreFeatures .break {
  clear: both;
}
.RobloxLandingPage .coreFeatures .description {
  color: #fff;
  font-size: 2.2rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .description {
    text-align: center;
  }
}
.RobloxLandingPage .coreFeatures .codeRoblox {
  position: relative;
  left: 13rem;
  top: -2rem;
}
.RobloxLandingPage .coreFeatures .codingProTop {
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .codingProTop {
    display: none;
  }
}
.RobloxLandingPage .coreFeatures .codingProBottom {
  position: relative;
  top: 0;
  left: 0;
}
.RobloxLandingPage .coreFeatures .codeRobloxLeft {
  width: 40%;
  float: left;
  margin-top: 20rem;
  position: relative;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .codeRobloxLeft {
    width: 100%;
    float: none;
    margin-top: 0;
  }
}
.RobloxLandingPage .coreFeatures .codeRobloxRight {
  width: 60%;
  float: right;
  margin-top: 7rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .codeRobloxRight {
    width: 100%;
    float: none;
    margin-top: 0;
    margin-left: -10rem;
  }
}
.RobloxLandingPage .coreFeatures .codingProLeft {
  width: 50%;
  float: left;
  margin-top: 15rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .codingProLeft {
    width: 100%;
    float: none;
    margin-top: 5rem;
  }
}
.RobloxLandingPage .coreFeatures .codingProRight {
  width: 40%;
  float: right;
  margin-top: 10rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .codingProRight {
    width: 100%;
    float: none;
    margin-top: 0;
  }
}
.RobloxLandingPage .coreFeatures .battleFriendsLeft {
  width: 40%;
  float: left;
  padding-bottom: 12rem;
  position: relative;
  z-index: 10;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .battleFriendsLeft {
    width: 100%;
    float: none;
    margin-top: 0;
  }
}
.RobloxLandingPage .coreFeatures .battleFriendsRight {
  width: 50%;
  float: right;
  margin-top: -12rem;
}
@media (max-width: 767px) {
  .RobloxLandingPage .coreFeatures .battleFriendsRight {
    width: 100%;
    float: none;
    margin-top: 0;
  }
}
.RobloxLandingPage .coreFeatures .bottom {
  position: absolute;
  bottom: 0;
  left: 20rem;
}
.RobloxLandingPage .featuresWrapper {
  background: #534468;
  position: relative;
}
.RobloxLandingPage .featuresWrapper > .featuresImageLeft {
  position: absolute;
  top: 5rem;
  left: 0;
}
@media (max-width: 991px) {
  .RobloxLandingPage .featuresWrapper > .featuresImageLeft {
    display: none;
  }
}
.RobloxLandingPage .featuresWrapper > .featuresImageRight {
  position: absolute;
  bottom: 5rem;
  right: 0;
}
@media (max-width: 991px) {
  .RobloxLandingPage .featuresWrapper > .featuresImageRight {
    display: none;
  }
}
.RobloxLandingPage .featuresWrapper .FeaturesList > .maxWidthContentWrapper > .header > .title {
  color: #fff;
}
.RobloxLandingPage .featuresWrapper .FeaturesList .bottomPipeImageFrame,
.RobloxLandingPage .featuresWrapper .FeaturesList .topCloudImageFrame,
.RobloxLandingPage .featuresWrapper .FeaturesList .topPipeImageFrame {
  display: none;
}
