/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.BallSprayer__ball {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  opacity: 0;
}
.BallSprayer__ball:nth-child(1n) {
  background-color: #534468;
}
.BallSprayer__ball:nth-child(2n) {
  background-color: #ff486b;
}
.BallSprayer__ball:nth-child(3n) {
  background-color: #4a82ff;
}
.BallSprayer__ball:nth-child(4n) {
  background-color: #aae053;
}
.BallSprayer__ball--0 {
  border-radius: 100%;
  left: 82%;
  -webkit-animation: BallSprayer-0 5.50004542441415s 4.683530591728486s infinite;
  animation: BallSprayer-0 5.4683784842506675s 6.176061174359426s infinite;
}
@-webkit-keyframes BallSprayer-0 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 75%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-0 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 13%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--1 {
  border-radius: 100%;
  left: 40%;
  -webkit-animation: BallSprayer-1 5.564087621335906s 1.5924964680632958s infinite;
  animation: BallSprayer-1 5.292676442419334s 9.632268644608818s infinite;
}
@-webkit-keyframes BallSprayer-1 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 24%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-1 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 41%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--2 {
  border-radius: 100%;
  left: 45%;
  -webkit-animation: BallSprayer-2 5.415266127847703s 3.0104835143725572s infinite;
  animation: BallSprayer-2 5.3369581848174885s 8.759700038589902s infinite;
}
@-webkit-keyframes BallSprayer-2 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 26%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-2 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 98%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--3 {
  border-radius: 100%;
  left: 74%;
  -webkit-animation: BallSprayer-3 5.671511366222073s 7.034419350203636s infinite;
  animation: BallSprayer-3 5.094115499369008s 3.05703030087745s infinite;
}
@-webkit-keyframes BallSprayer-3 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 16%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-3 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 21%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--4 {
  border-radius: 100%;
  left: 19%;
  -webkit-animation: BallSprayer-4 5.35086767666231s 5.105020950845689s infinite;
  animation: BallSprayer-4 5.645808755510872s 6.558296084542389s infinite;
}
@-webkit-keyframes BallSprayer-4 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 48%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-4 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 84%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--5 {
  border-radius: 100%;
  left: 11%;
  -webkit-animation: BallSprayer-5 5.575648642359631s 1.9471737384332877s infinite;
  animation: BallSprayer-5 5.5868836886858855s 1.3296288528264988s infinite;
}
@-webkit-keyframes BallSprayer-5 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 53%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-5 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 84%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--6 {
  border-radius: 100%;
  left: 26%;
  -webkit-animation: BallSprayer-6 5.781326335296896s 1.2849601074791472s infinite;
  animation: BallSprayer-6 5.8557738307637806s 7.8445603844831595s infinite;
}
@-webkit-keyframes BallSprayer-6 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 88%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-6 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 12%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--7 {
  border-radius: 100%;
  left: 6%;
  -webkit-animation: BallSprayer-7 5.064036250222495s 1.5568020592253062s infinite;
  animation: BallSprayer-7 5.671378566129381s 6.390793545273057s infinite;
}
@-webkit-keyframes BallSprayer-7 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 59%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-7 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 22%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--8 {
  border-radius: 100%;
  left: 41%;
  -webkit-animation: BallSprayer-8 5.345780729859105s 4.037123134208653s infinite;
  animation: BallSprayer-8 5.247967958753056s 3.2231930829843125s infinite;
}
@-webkit-keyframes BallSprayer-8 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 70%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-8 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 71%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--9 {
  border-radius: 100%;
  left: 59%;
  -webkit-animation: BallSprayer-9 5.6165148445411095s 6.924838694204809s infinite;
  animation: BallSprayer-9 5.644293967818276s 3.433578323177504s infinite;
}
@-webkit-keyframes BallSprayer-9 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 24%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-9 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 88%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--10 {
  border-radius: 100%;
  left: 24%;
  -webkit-animation: BallSprayer-10 5.699805722146413s 2.8047213686701222s infinite;
  animation: BallSprayer-10 5.474430627013597s 5.111704749965805s infinite;
}
@-webkit-keyframes BallSprayer-10 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 2%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-10 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 35%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--11 {
  border-radius: 100%;
  left: 79%;
  -webkit-animation: BallSprayer-11 5.687582746680761s 1.594137331855996s infinite;
  animation: BallSprayer-11 5.409026377135016s 0.017047226815369854s infinite;
}
@-webkit-keyframes BallSprayer-11 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 62%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-11 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 66%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--12 {
  border-radius: 100%;
  left: 67%;
  -webkit-animation: BallSprayer-12 5.749538606346169s 3.8597815697506377s infinite;
  animation: BallSprayer-12 5.13356661755768s 6.247354592498258s infinite;
}
@-webkit-keyframes BallSprayer-12 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 29%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-12 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 34%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--13 {
  border-radius: 100%;
  left: 19%;
  -webkit-animation: BallSprayer-13 5.254315135456485s 1.6944375888890306s infinite;
  animation: BallSprayer-13 5.656311960189411s 1.0752375092540678s infinite;
}
@-webkit-keyframes BallSprayer-13 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 73%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-13 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 8%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--14 {
  border-radius: 100%;
  left: 34%;
  -webkit-animation: BallSprayer-14 5.536924975323021s 2.9130596903420125s infinite;
  animation: BallSprayer-14 5.711373860940657s 8.739752933383464s infinite;
}
@-webkit-keyframes BallSprayer-14 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 26%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-14 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 85%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--15 {
  border-radius: 100%;
  left: 94%;
  -webkit-animation: BallSprayer-15 5.891692236201049s 6.4358755247079s infinite;
  animation: BallSprayer-15 5.20261304981526s 7.14543716022094s infinite;
}
@-webkit-keyframes BallSprayer-15 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 66%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-15 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 9%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--16 {
  border-radius: 100%;
  left: 51%;
  -webkit-animation: BallSprayer-16 5.005161709023749s 2.5048181465298502s infinite;
  animation: BallSprayer-16 5.835878083806044s 6.170465983832861s infinite;
}
@-webkit-keyframes BallSprayer-16 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 32%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-16 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 92%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--17 {
  border-radius: 100%;
  left: 91%;
  -webkit-animation: BallSprayer-17 5.7005029667860505s 4.434677531133502s infinite;
  animation: BallSprayer-17 5.640249178373867s 3.2113689199862394s infinite;
}
@-webkit-keyframes BallSprayer-17 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 73%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-17 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 19%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--18 {
  border-radius: 100%;
  left: 67%;
  -webkit-animation: BallSprayer-18 5.824406027140477s 5.202779765883648s infinite;
  animation: BallSprayer-18 5.40152051344658s 5.736822416770664s infinite;
}
@-webkit-keyframes BallSprayer-18 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 66%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-18 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 39%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--19 {
  border-radius: 100%;
  left: 15%;
  -webkit-animation: BallSprayer-19 5.964789306412952s 2.9021127275006564s infinite;
  animation: BallSprayer-19 5.975257428881387s 1.5211231497549238s infinite;
}
@-webkit-keyframes BallSprayer-19 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 5%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-19 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 1%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--20 {
  border-radius: 100%;
  left: 39%;
  -webkit-animation: BallSprayer-20 5.3776358486994225s 3.899510365616643s infinite;
  animation: BallSprayer-20 5.82884798539461s 1.9923478597391164s infinite;
}
@-webkit-keyframes BallSprayer-20 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 57%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-20 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 47%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--21 {
  border-radius: 100%;
  left: 6%;
  -webkit-animation: BallSprayer-21 5.121588443466312s 7.586848521329211s infinite;
  animation: BallSprayer-21 5.302563615343172s 7.315713409441383s infinite;
}
@-webkit-keyframes BallSprayer-21 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 60%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-21 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 25%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--22 {
  border-radius: 100%;
  left: 15%;
  -webkit-animation: BallSprayer-22 5.102737038529242s 1.2143243922553348s infinite;
  animation: BallSprayer-22 5.085016854386394s 0.6065166244527376s infinite;
}
@-webkit-keyframes BallSprayer-22 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 50%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-22 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 77%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--23 {
  border-radius: 100%;
  left: 10%;
  -webkit-animation: BallSprayer-23 5.402890785839844s 4.627742797955047s infinite;
  animation: BallSprayer-23 5.013527401805228s 4.928202681033634s infinite;
}
@-webkit-keyframes BallSprayer-23 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 24%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-23 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 65%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--24 {
  border-radius: 100%;
  left: 39%;
  -webkit-animation: BallSprayer-24 5.830331109927419s 2.8672957258533693s infinite;
  animation: BallSprayer-24 5.428596250857897s 7.889894159239339s infinite;
}
@-webkit-keyframes BallSprayer-24 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 36%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-24 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 98%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--25 {
  border-radius: 100%;
  left: 34%;
  -webkit-animation: BallSprayer-25 5.9876021373292145s 3.322951775712406s infinite;
  animation: BallSprayer-25 5.701234591321717s 6.053169352874783s infinite;
}
@-webkit-keyframes BallSprayer-25 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 21%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-25 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 65%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--26 {
  border-radius: 100%;
  left: 46%;
  -webkit-animation: BallSprayer-26 5.062069806552003s 1.0835425610422544s infinite;
  animation: BallSprayer-26 5.9848132022933225s 8.122415730300549s infinite;
}
@-webkit-keyframes BallSprayer-26 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 86%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-26 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 96%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--27 {
  border-radius: 100%;
  left: 42%;
  -webkit-animation: BallSprayer-27 5.180030444497002s 6.482070541560034s infinite;
  animation: BallSprayer-27 5.251007174907216s 1.934063230610632s infinite;
}
@-webkit-keyframes BallSprayer-27 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 85%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-27 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 54%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--28 {
  border-radius: 100%;
  left: 14%;
  -webkit-animation: BallSprayer-28 5.039314641640573s 1.699132935210983s infinite;
  animation: BallSprayer-28 5.123671084073173s 6.6750343060632495s infinite;
}
@-webkit-keyframes BallSprayer-28 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 32%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-28 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 50%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--29 {
  border-radius: 100%;
  left: 90%;
  -webkit-animation: BallSprayer-29 5.72015671241164s 1.2007132285367583s infinite;
  animation: BallSprayer-29 5.242856931311071s 2.4389626037883105s infinite;
}
@-webkit-keyframes BallSprayer-29 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 70%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-29 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 38%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--30 {
  border-radius: 100%;
  left: 13%;
  -webkit-animation: BallSprayer-30 5.287546251302643s 6.358005756772183s infinite;
  animation: BallSprayer-30 5.384928529073779s 6.934383445408397s infinite;
}
@-webkit-keyframes BallSprayer-30 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 13%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-30 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 89%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--31 {
  border-radius: 100%;
  left: 51%;
  -webkit-animation: BallSprayer-31 5.801837138050208s 3.36415088785174s infinite;
  animation: BallSprayer-31 5.952816177483222s 1.6443986442100966s infinite;
}
@-webkit-keyframes BallSprayer-31 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 12%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-31 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 95%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--32 {
  border-radius: 100%;
  left: 68%;
  -webkit-animation: BallSprayer-32 5.33954377994366s 2.1270737613161383s infinite;
  animation: BallSprayer-32 5.730086905171243s 0.01630491110697907s infinite;
}
@-webkit-keyframes BallSprayer-32 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 69%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-32 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 53%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--33 {
  border-radius: 100%;
  left: 44%;
  -webkit-animation: BallSprayer-33 5.582057134669352s 1.1888109396314817s infinite;
  animation: BallSprayer-33 5.502337818947553s 8.792670368568485s infinite;
}
@-webkit-keyframes BallSprayer-33 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 92%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-33 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 64%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--34 {
  border-radius: 100%;
  left: 30%;
  -webkit-animation: BallSprayer-34 5.044763668614042s 2.8216386556311157s infinite;
  animation: BallSprayer-34 5.811034506568042s 6.004091641229426s infinite;
}
@-webkit-keyframes BallSprayer-34 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 83%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-34 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 10%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--35 {
  border-radius: 100%;
  left: 81%;
  -webkit-animation: BallSprayer-35 5.7014722898910914s 5.885002510176607s infinite;
  animation: BallSprayer-35 5.7422605283511s 4.241910298385823s infinite;
}
@-webkit-keyframes BallSprayer-35 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 55%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-35 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 70%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--36 {
  border-radius: 100%;
  left: 81%;
  -webkit-animation: BallSprayer-36 5.6957382185678975s 6.735771674072049s infinite;
  animation: BallSprayer-36 5.442870430261907s 2.0424400711137136s infinite;
}
@-webkit-keyframes BallSprayer-36 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 57%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-36 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 4%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--37 {
  border-radius: 100%;
  left: 9%;
  -webkit-animation: BallSprayer-37 5.027476860242372s 5.4673070942563395s infinite;
  animation: BallSprayer-37 5.601234714277592s 1.4143810226054976s infinite;
}
@-webkit-keyframes BallSprayer-37 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 66%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-37 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 25%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--38 {
  border-radius: 100%;
  left: 10%;
  -webkit-animation: BallSprayer-38 5.982360408692394s 2.723669588454891s infinite;
  animation: BallSprayer-38 5.705428128284445s 7.241890767093895s infinite;
}
@-webkit-keyframes BallSprayer-38 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 78%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-38 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 8%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--39 {
  border-radius: 100%;
  left: 77%;
  -webkit-animation: BallSprayer-39 5.425794733687986s 8.201419678464958s infinite;
  animation: BallSprayer-39 5.67932332358814s 6.661999032673787s infinite;
}
@-webkit-keyframes BallSprayer-39 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 23%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-39 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 4%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--40 {
  border-radius: 100%;
  left: 38%;
  -webkit-animation: BallSprayer-40 5.577094797961064s 9.38853384891951s infinite;
  animation: BallSprayer-40 5.674789763688709s 4.1673209810947505s infinite;
}
@-webkit-keyframes BallSprayer-40 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 6%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-40 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 20%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--41 {
  border-radius: 100%;
  left: 83%;
  -webkit-animation: BallSprayer-41 5.465131017146731s 3.849030470946637s infinite;
  animation: BallSprayer-41 5.462566303062701s 2.21488138807725s infinite;
}
@-webkit-keyframes BallSprayer-41 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 58%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-41 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 13%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--42 {
  border-radius: 100%;
  left: 18%;
  -webkit-animation: BallSprayer-42 5.3377868756505755s 2.7314236722826957s infinite;
  animation: BallSprayer-42 5.778283218227466s 0.9152157126764582s infinite;
}
@-webkit-keyframes BallSprayer-42 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 32%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-42 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 87%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--43 {
  border-radius: 100%;
  left: 69%;
  -webkit-animation: BallSprayer-43 5.013030795822361s 9.956981304615525s infinite;
  animation: BallSprayer-43 5.16698620796532s 0.8663901800919804s infinite;
}
@-webkit-keyframes BallSprayer-43 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 25%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-43 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 60%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--44 {
  border-radius: 100%;
  left: 87%;
  -webkit-animation: BallSprayer-44 5.49995169905383s 4.4694314203801255s infinite;
  animation: BallSprayer-44 5.593832204852205s 7.121530008160102s infinite;
}
@-webkit-keyframes BallSprayer-44 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 97%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-44 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 17%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--45 {
  border-radius: 100%;
  left: 97%;
  -webkit-animation: BallSprayer-45 5.4318459852557055s 3.489451111165245s infinite;
  animation: BallSprayer-45 5.766853803553422s 1.6742130734315963s infinite;
}
@-webkit-keyframes BallSprayer-45 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 80%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-45 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 81%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--46 {
  border-radius: 100%;
  left: 83%;
  -webkit-animation: BallSprayer-46 5.247671668169248s 7.922973789360396s infinite;
  animation: BallSprayer-46 5.744763409303709s 9.642341745291656s infinite;
}
@-webkit-keyframes BallSprayer-46 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 96%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-46 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 99%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--47 {
  border-radius: 100%;
  left: 23%;
  -webkit-animation: BallSprayer-47 5.034646463642318s 4.792312730848347s infinite;
  animation: BallSprayer-47 5.640173294381705s 1.2747132495333546s infinite;
}
@-webkit-keyframes BallSprayer-47 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 70%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-47 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 90%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--48 {
  border-radius: 100%;
  left: 63%;
  -webkit-animation: BallSprayer-48 5.711412980485327s 3.912711675033229s infinite;
  animation: BallSprayer-48 5.362524337592715s 5.8525117357047485s infinite;
}
@-webkit-keyframes BallSprayer-48 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 91%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-48 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 30%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--49 {
  border-radius: 100%;
  left: 44%;
  -webkit-animation: BallSprayer-49 5.367684687552224s 6.628776308686815s infinite;
  animation: BallSprayer-49 5.600420168672978s 4.91451061230459s infinite;
}
@-webkit-keyframes BallSprayer-49 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 74%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-49 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 13%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--50 {
  border-radius: 100%;
  left: 9%;
  -webkit-animation: BallSprayer-50 5.140498235678772s 9.198958711804156s infinite;
  animation: BallSprayer-50 5.7645874075635595s 0.7810202019051027s infinite;
}
@-webkit-keyframes BallSprayer-50 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 11%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-50 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 6%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--51 {
  border-radius: 100%;
  left: 25%;
  -webkit-animation: BallSprayer-51 5.839782987102548s 3.2037278789476265s infinite;
  animation: BallSprayer-51 5.750836642825341s 0.8313411822957906s infinite;
}
@-webkit-keyframes BallSprayer-51 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 99%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-51 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 64%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--52 {
  border-radius: 100%;
  left: 47%;
  -webkit-animation: BallSprayer-52 5.448407035392404s 9.088284882930925s infinite;
  animation: BallSprayer-52 5.806893745879149s 6.814115682073185s infinite;
}
@-webkit-keyframes BallSprayer-52 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 36%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-52 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 41%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--53 {
  border-radius: 100%;
  left: 93%;
  -webkit-animation: BallSprayer-53 5.31854327882177s 1.4358909140234832s infinite;
  animation: BallSprayer-53 5.313283937745089s 0.4009550749226709s infinite;
}
@-webkit-keyframes BallSprayer-53 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 69%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-53 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 67%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--54 {
  border-radius: 100%;
  left: 59%;
  -webkit-animation: BallSprayer-54 5.88138156405912s 9.183953538890728s infinite;
  animation: BallSprayer-54 5.183476703360817s 0.11578175743196084s infinite;
}
@-webkit-keyframes BallSprayer-54 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 3%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-54 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 21%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--55 {
  border-radius: 100%;
  left: 35%;
  -webkit-animation: BallSprayer-55 5.61593856489928s 0.7976100609434655s infinite;
  animation: BallSprayer-55 5.299712034634869s 7.9961461777837055s infinite;
}
@-webkit-keyframes BallSprayer-55 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 6%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-55 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 15%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--56 {
  border-radius: 100%;
  left: 6%;
  -webkit-animation: BallSprayer-56 5.349174465690422s 5.0017068906064255s infinite;
  animation: BallSprayer-56 5.150206803860439s 2.062389341674322s infinite;
}
@-webkit-keyframes BallSprayer-56 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 14%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-56 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 19%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--57 {
  border-radius: 100%;
  left: 58%;
  -webkit-animation: BallSprayer-57 5.160351481038601s 0.4876491026530605s infinite;
  animation: BallSprayer-57 5.176281336854605s 9.917976692040495s infinite;
}
@-webkit-keyframes BallSprayer-57 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 81%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-57 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 1%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--58 {
  border-radius: 100%;
  left: 93%;
  -webkit-animation: BallSprayer-58 5.1114666966376445s 4.685834080867346s infinite;
  animation: BallSprayer-58 5.246303955300298s 2.0867794639182446s infinite;
}
@-webkit-keyframes BallSprayer-58 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 36%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-58 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 20%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--59 {
  border-radius: 100%;
  left: 45%;
  -webkit-animation: BallSprayer-59 5.444828525030835s 9.5203613666662s infinite;
  animation: BallSprayer-59 5.3341685346472s 7.36510766086393s infinite;
}
@-webkit-keyframes BallSprayer-59 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 24%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-59 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 45%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--60 {
  border-radius: 100%;
  left: 96%;
  -webkit-animation: BallSprayer-60 5.208830457676716s 1.2573968178943695s infinite;
  animation: BallSprayer-60 5.792039975303363s 2.1906421468834902s infinite;
}
@-webkit-keyframes BallSprayer-60 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 16%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-60 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 76%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--61 {
  border-radius: 100%;
  left: 19%;
  -webkit-animation: BallSprayer-61 5.244220738972185s 7.000842977650919s infinite;
  animation: BallSprayer-61 5.658263819871122s 6.733710429858039s infinite;
}
@-webkit-keyframes BallSprayer-61 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 97%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-61 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 57%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--62 {
  border-radius: 100%;
  left: 97%;
  -webkit-animation: BallSprayer-62 5.738638031859942s 5.49625371456994s infinite;
  animation: BallSprayer-62 5.132270105414495s 0.9304377191013535s infinite;
}
@-webkit-keyframes BallSprayer-62 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 76%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-62 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 11%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--63 {
  border-radius: 100%;
  left: 73%;
  -webkit-animation: BallSprayer-63 5.5969090861311095s 1.819948014280568s infinite;
  animation: BallSprayer-63 5.083205656651577s 0.967756673300566s infinite;
}
@-webkit-keyframes BallSprayer-63 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 22%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-63 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 37%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--64 {
  border-radius: 100%;
  left: 66%;
  -webkit-animation: BallSprayer-64 5.044787652234658s 6.468061254233543s infinite;
  animation: BallSprayer-64 5.429382057412218s 8.785672376821045s infinite;
}
@-webkit-keyframes BallSprayer-64 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 88%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-64 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 95%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--65 {
  border-radius: 100%;
  left: 54%;
  -webkit-animation: BallSprayer-65 5.165952431126069s 2.3765785982672916s infinite;
  animation: BallSprayer-65 5.755290131998237s 2.008217344499028s infinite;
}
@-webkit-keyframes BallSprayer-65 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 66%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-65 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 63%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--66 {
  border-radius: 100%;
  left: 58%;
  -webkit-animation: BallSprayer-66 5.831213963614012s 3.5998724804426208s infinite;
  animation: BallSprayer-66 5.578912734277781s 4.613654306302768s infinite;
}
@-webkit-keyframes BallSprayer-66 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 39%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-66 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 1%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--67 {
  border-radius: 100%;
  left: 87%;
  -webkit-animation: BallSprayer-67 5.805321671257689s 8.096986856332855s infinite;
  animation: BallSprayer-67 5.037818435957195s 7.995230007435962s infinite;
}
@-webkit-keyframes BallSprayer-67 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 89%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-67 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 79%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--68 {
  border-radius: 100%;
  left: 46%;
  -webkit-animation: BallSprayer-68 5.3969486533663895s 7.198878118176011s infinite;
  animation: BallSprayer-68 5.106343242772462s 0.6804212280390232s infinite;
}
@-webkit-keyframes BallSprayer-68 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 53%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-68 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 42%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--69 {
  border-radius: 100%;
  left: 68%;
  -webkit-animation: BallSprayer-69 5.628250801232953s 8.71839502249584s infinite;
  animation: BallSprayer-69 5.252361327175404s 0.5256707355028722s infinite;
}
@-webkit-keyframes BallSprayer-69 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 84%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-69 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 40%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--70 {
  border-radius: 100%;
  left: 17%;
  -webkit-animation: BallSprayer-70 5.113974655688909s 3.6411290299979737s infinite;
  animation: BallSprayer-70 5.736335037559246s 6.849307189133729s infinite;
}
@-webkit-keyframes BallSprayer-70 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 75%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-70 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 25%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--71 {
  border-radius: 100%;
  left: 88%;
  -webkit-animation: BallSprayer-71 5.125823229129557s 3.690261400476811s infinite;
  animation: BallSprayer-71 5.800983512426586s 7.725350068388721s infinite;
}
@-webkit-keyframes BallSprayer-71 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 8%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-71 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 79%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--72 {
  border-radius: 100%;
  left: 81%;
  -webkit-animation: BallSprayer-72 5.555333189815285s 2.9570246240508657s infinite;
  animation: BallSprayer-72 5.056555723719539s 2.4179562094285623s infinite;
}
@-webkit-keyframes BallSprayer-72 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 48%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-72 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 86%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--73 {
  border-radius: 100%;
  left: 33%;
  -webkit-animation: BallSprayer-73 5.825729233273336s 9.654367521429899s infinite;
  animation: BallSprayer-73 5.522372440431354s 9.913464259956857s infinite;
}
@-webkit-keyframes BallSprayer-73 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 77%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-73 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 45%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--74 {
  border-radius: 100%;
  left: 98%;
  -webkit-animation: BallSprayer-74 5.736761973344478s 3.886174139529046s infinite;
  animation: BallSprayer-74 5.645473060601656s 8.894921717955524s infinite;
}
@-webkit-keyframes BallSprayer-74 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 90%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-74 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 2%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--75 {
  border-radius: 100%;
  left: 13%;
  -webkit-animation: BallSprayer-75 5.499984836721733s 8.793828181224573s infinite;
  animation: BallSprayer-75 5.550173529526273s 7.582523449599616s infinite;
}
@-webkit-keyframes BallSprayer-75 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 93%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-75 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 10%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--76 {
  border-radius: 100%;
  left: 54%;
  -webkit-animation: BallSprayer-76 5.524090954047815s 9.906357509406147s infinite;
  animation: BallSprayer-76 5.066771080090432s 9.875539617451443s infinite;
}
@-webkit-keyframes BallSprayer-76 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 93%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-76 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 20%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--77 {
  border-radius: 100%;
  left: 50%;
  -webkit-animation: BallSprayer-77 5.265351690370862s 8.52251875606868s infinite;
  animation: BallSprayer-77 5.089588827999681s 6.083476950855989s infinite;
}
@-webkit-keyframes BallSprayer-77 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 46%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-77 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 69%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--78 {
  border-radius: 100%;
  left: 100%;
  -webkit-animation: BallSprayer-78 5.1849343950011875s 8.175289637748897s infinite;
  animation: BallSprayer-78 5.178364375474294s 7.606363586850451s infinite;
}
@-webkit-keyframes BallSprayer-78 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 6%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-78 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 20%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--79 {
  border-radius: 100%;
  left: 34%;
  -webkit-animation: BallSprayer-79 5.611487573368578s 9.497195752156635s infinite;
  animation: BallSprayer-79 5.696903570443874s 9.515868066001056s infinite;
}
@-webkit-keyframes BallSprayer-79 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 73%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-79 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 60%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--80 {
  border-radius: 100%;
  left: 57%;
  -webkit-animation: BallSprayer-80 5.774702247411957s 0.7729187866647669s infinite;
  animation: BallSprayer-80 5.718941024314364s 8.57014827722917s infinite;
}
@-webkit-keyframes BallSprayer-80 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 8%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-80 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 89%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--81 {
  border-radius: 100%;
  left: 12%;
  -webkit-animation: BallSprayer-81 5.035556792422916s 9.370726328074422s infinite;
  animation: BallSprayer-81 5.407593793529715s 4.877891519064466s infinite;
}
@-webkit-keyframes BallSprayer-81 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 10%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-81 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 26%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--82 {
  border-radius: 100%;
  left: 23%;
  -webkit-animation: BallSprayer-82 5.891267476535656s 1.1755070195800688s infinite;
  animation: BallSprayer-82 5.262547340731367s 2.4678199088649455s infinite;
}
@-webkit-keyframes BallSprayer-82 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 32%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-82 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 70%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--83 {
  border-radius: 100%;
  left: 43%;
  -webkit-animation: BallSprayer-83 5.252419271419042s 4.107930980896166s infinite;
  animation: BallSprayer-83 5.44746784643647s 6.29262295878366s infinite;
}
@-webkit-keyframes BallSprayer-83 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 22%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-83 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 62%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--84 {
  border-radius: 100%;
  left: 16%;
  -webkit-animation: BallSprayer-84 5.0875697279155725s 2.3596346495576026s infinite;
  animation: BallSprayer-84 5.335304438988642s 7.367565432264172s infinite;
}
@-webkit-keyframes BallSprayer-84 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 89%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-84 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 19%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--85 {
  border-radius: 100%;
  left: 29%;
  -webkit-animation: BallSprayer-85 5.459430083156304s 6.953333090435329s infinite;
  animation: BallSprayer-85 5.537832296546951s 9.537441504775465s infinite;
}
@-webkit-keyframes BallSprayer-85 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 7%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-85 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 55%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--86 {
  border-radius: 100%;
  left: 53%;
  -webkit-animation: BallSprayer-86 5.935208187955538s 9.890853859287157s infinite;
  animation: BallSprayer-86 5.144605543161855s 3.096857776246258s infinite;
}
@-webkit-keyframes BallSprayer-86 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 96%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-86 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 62%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--87 {
  border-radius: 100%;
  left: 60%;
  -webkit-animation: BallSprayer-87 5.797028851431502s 9.344045766494244s infinite;
  animation: BallSprayer-87 5.055478951451687s 6.328726126912243s infinite;
}
@-webkit-keyframes BallSprayer-87 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 7%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-87 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 96%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--88 {
  border-radius: 100%;
  left: 12%;
  -webkit-animation: BallSprayer-88 5.131645703821555s 9.441717625866264s infinite;
  animation: BallSprayer-88 5.7071484797660545s 3.4201229514416553s infinite;
}
@-webkit-keyframes BallSprayer-88 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 46%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-88 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 93%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--89 {
  border-radius: 100%;
  left: 42%;
  -webkit-animation: BallSprayer-89 5.277782024288162s 0.12974760163443566s infinite;
  animation: BallSprayer-89 5.313999543047524s 3.358861239623674s infinite;
}
@-webkit-keyframes BallSprayer-89 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 95%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-89 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 29%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--90 {
  border-radius: 100%;
  left: 55%;
  -webkit-animation: BallSprayer-90 5.693628750464257s 7.140192454364338s infinite;
  animation: BallSprayer-90 5.070707211855832s 7.340927269505881s infinite;
}
@-webkit-keyframes BallSprayer-90 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 55%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-90 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 69%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--91 {
  border-radius: 100%;
  left: 98%;
  -webkit-animation: BallSprayer-91 5.21931066685519s 4.064919549422492s infinite;
  animation: BallSprayer-91 5.703743206344022s 1.5605473324107288s infinite;
}
@-webkit-keyframes BallSprayer-91 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 88%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-91 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 10%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--92 {
  border-radius: 100%;
  left: 88%;
  -webkit-animation: BallSprayer-92 5.455712505158013s 6.902722288698138s infinite;
  animation: BallSprayer-92 5.9711031003506125s 4.379204773362233s infinite;
}
@-webkit-keyframes BallSprayer-92 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 36%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-92 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 79%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--93 {
  border-radius: 100%;
  left: 71%;
  -webkit-animation: BallSprayer-93 5.132380096314874s 5.294301599372702s infinite;
  animation: BallSprayer-93 5.401886910449845s 5.469228538747886s infinite;
}
@-webkit-keyframes BallSprayer-93 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 77%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-93 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 20%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--94 {
  border-radius: 100%;
  left: 71%;
  -webkit-animation: BallSprayer-94 5.978287895180613s 3.7998869210127983s infinite;
  animation: BallSprayer-94 5.538234242700288s 5.272251881961463s infinite;
}
@-webkit-keyframes BallSprayer-94 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 24%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-94 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 76%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--95 {
  border-radius: 100%;
  left: 12%;
  -webkit-animation: BallSprayer-95 5.141421907839431s 5.348182069803786s infinite;
  animation: BallSprayer-95 5.173225462678987s 6.591225918624122s infinite;
}
@-webkit-keyframes BallSprayer-95 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 86%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-95 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 90%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--96 {
  border-radius: 100%;
  left: 10%;
  -webkit-animation: BallSprayer-96 5.1863497083703125s 6.9575113267717414s infinite;
  animation: BallSprayer-96 5.223881559897805s 2.7060065327283978s infinite;
}
@-webkit-keyframes BallSprayer-96 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 46%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-96 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 57%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--97 {
  border-radius: 100%;
  left: 93%;
  -webkit-animation: BallSprayer-97 5.549958467244273s 7.5074811369910925s infinite;
  animation: BallSprayer-97 5.333444488074155s 9.622927174005088s infinite;
}
@-webkit-keyframes BallSprayer-97 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 72%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-97 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 63%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--98 {
  border-radius: 100%;
  left: 93%;
  -webkit-animation: BallSprayer-98 5.336325999994747s 1.5267832324157293s infinite;
  animation: BallSprayer-98 5.096229125597977s 3.8272730927178955s infinite;
}
@-webkit-keyframes BallSprayer-98 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 77%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-98 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 48%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--99 {
  border-radius: 100%;
  left: 29%;
  -webkit-animation: BallSprayer-99 5.243926688061596s 0.5160206042406745s infinite;
  animation: BallSprayer-99 5.842862712455714s 3.455365311918641s infinite;
}
@-webkit-keyframes BallSprayer-99 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 75%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-99 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 80%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--100 {
  border-radius: 100%;
  left: 76%;
  -webkit-animation: BallSprayer-100 5.680971855047966s 6.943628628843637s infinite;
  animation: BallSprayer-100 5.211084266012178s 6.06095089055218s infinite;
}
@-webkit-keyframes BallSprayer-100 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 39%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-100 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 61%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--101 {
  border-radius: 100%;
  left: 97%;
  -webkit-animation: BallSprayer-101 5.826229653701583s 9.217008121850014s infinite;
  animation: BallSprayer-101 5.1106584060964035s 2.587043665303055s infinite;
}
@-webkit-keyframes BallSprayer-101 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 47%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-101 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 90%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--102 {
  border-radius: 100%;
  left: 67%;
  -webkit-animation: BallSprayer-102 5.761885886266634s 3.9364731904049655s infinite;
  animation: BallSprayer-102 5.293341627731665s 5.074022627585988s infinite;
}
@-webkit-keyframes BallSprayer-102 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 20%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-102 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 7%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--103 {
  border-radius: 100%;
  left: 86%;
  -webkit-animation: BallSprayer-103 5.029158033291847s 1.4345383276442014s infinite;
  animation: BallSprayer-103 5.350675758924741s 8.605823075406597s infinite;
}
@-webkit-keyframes BallSprayer-103 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 11%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-103 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 96%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--104 {
  border-radius: 100%;
  left: 8%;
  -webkit-animation: BallSprayer-104 5.4277955096097035s 8.119390838379026s infinite;
  animation: BallSprayer-104 5.757757829333109s 8.348190614813348s infinite;
}
@-webkit-keyframes BallSprayer-104 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 52%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-104 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 49%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--105 {
  border-radius: 100%;
  left: 32%;
  -webkit-animation: BallSprayer-105 5.674504857838042s 2.063507777841771s infinite;
  animation: BallSprayer-105 5.779176398659563s 6.373894589504545s infinite;
}
@-webkit-keyframes BallSprayer-105 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 53%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-105 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 88%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--106 {
  border-radius: 100%;
  left: 27%;
  -webkit-animation: BallSprayer-106 5.9441162164882755s 1.7148072710190854s infinite;
  animation: BallSprayer-106 5.251826000458937s 6.9040344431403184s infinite;
}
@-webkit-keyframes BallSprayer-106 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 81%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-106 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 25%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--107 {
  border-radius: 100%;
  left: 17%;
  -webkit-animation: BallSprayer-107 5.189870596406436s 9.716375926538985s infinite;
  animation: BallSprayer-107 5.017053103299208s 3.9457900537664936s infinite;
}
@-webkit-keyframes BallSprayer-107 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 38%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-107 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 76%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--108 {
  border-radius: 100%;
  left: 51%;
  -webkit-animation: BallSprayer-108 5.592705945922194s 3.612140763343674s infinite;
  animation: BallSprayer-108 5.212976812048206s 2.9770948309242096s infinite;
}
@-webkit-keyframes BallSprayer-108 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 1%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-108 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 49%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--109 {
  border-radius: 100%;
  left: 43%;
  -webkit-animation: BallSprayer-109 5.231076378937969s 9.668271100681952s infinite;
  animation: BallSprayer-109 5.838405382833935s 7.6555834843740955s infinite;
}
@-webkit-keyframes BallSprayer-109 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 60%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-109 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 73%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--110 {
  border-radius: 100%;
  left: 8%;
  -webkit-animation: BallSprayer-110 5.9216584077119885s 2.5804397215094954s infinite;
  animation: BallSprayer-110 5.062338429524694s 3.526170364338619s infinite;
}
@-webkit-keyframes BallSprayer-110 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 96%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-110 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 23%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--111 {
  border-radius: 100%;
  left: 12%;
  -webkit-animation: BallSprayer-111 5.132316575425754s 8.565082429309015s infinite;
  animation: BallSprayer-111 5.630066795200177s 7.194238613083557s infinite;
}
@-webkit-keyframes BallSprayer-111 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 89%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-111 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 12%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--112 {
  border-radius: 100%;
  left: 75%;
  -webkit-animation: BallSprayer-112 5.219953906550496s 6.012971545596817s infinite;
  animation: BallSprayer-112 5.2753217107880435s 2.3432563450714294s infinite;
}
@-webkit-keyframes BallSprayer-112 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 2%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-112 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 81%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--113 {
  border-radius: 100%;
  left: 70%;
  -webkit-animation: BallSprayer-113 5.942119886399675s 2.145397020853108s infinite;
  animation: BallSprayer-113 5.313946317700335s 4.868371608094202s infinite;
}
@-webkit-keyframes BallSprayer-113 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 5%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-113 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 76%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--114 {
  border-radius: 100%;
  left: 8%;
  -webkit-animation: BallSprayer-114 5.722724298834346s 0.3143282344803744s infinite;
  animation: BallSprayer-114 5.4832156648550665s 8.906999340891717s infinite;
}
@-webkit-keyframes BallSprayer-114 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 17%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-114 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 15%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--115 {
  border-radius: 100%;
  left: 82%;
  -webkit-animation: BallSprayer-115 5.8013748734547255s 4.203088840792728s infinite;
  animation: BallSprayer-115 5.783849409820861s 0.0003548115095131088s infinite;
}
@-webkit-keyframes BallSprayer-115 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 68%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-115 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 13%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--116 {
  border-radius: 100%;
  left: 23%;
  -webkit-animation: BallSprayer-116 5.300648560100871s 9.577277304045236s infinite;
  animation: BallSprayer-116 5.711069472050569s 8.382440310416223s infinite;
}
@-webkit-keyframes BallSprayer-116 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 30%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-116 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 64%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--117 {
  border-radius: 100%;
  left: 91%;
  -webkit-animation: BallSprayer-117 5.172899839086281s 3.052256726442666s infinite;
  animation: BallSprayer-117 5.776378112206505s 4.980777461927623s infinite;
}
@-webkit-keyframes BallSprayer-117 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 47%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-117 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 49%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--118 {
  border-radius: 100%;
  left: 45%;
  -webkit-animation: BallSprayer-118 5.254073904748464s 7.379209461360825s infinite;
  animation: BallSprayer-118 5.172975041748875s 3.239743422305379s infinite;
}
@-webkit-keyframes BallSprayer-118 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 53%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-118 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 94%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--119 {
  border-radius: 100%;
  left: 1%;
  -webkit-animation: BallSprayer-119 5.092016753695776s 1.0147858370496254s infinite;
  animation: BallSprayer-119 5.787917945417706s 3.6252719746062634s infinite;
}
@-webkit-keyframes BallSprayer-119 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 19%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-119 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 16%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--120 {
  border-radius: 100%;
  left: 4%;
  -webkit-animation: BallSprayer-120 5.821594669326313s 9.417540034401764s infinite;
  animation: BallSprayer-120 5.884292096427398s 8.446863644265488s infinite;
}
@-webkit-keyframes BallSprayer-120 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 74%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-120 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 77%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--121 {
  border-radius: 100%;
  left: 1%;
  -webkit-animation: BallSprayer-121 5.980218982433955s 5.331312943315192s infinite;
  animation: BallSprayer-121 5.43669403967212s 0.7754015382986479s infinite;
}
@-webkit-keyframes BallSprayer-121 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 72%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-121 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 8%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--122 {
  border-radius: 100%;
  left: 43%;
  -webkit-animation: BallSprayer-122 5.562454937320831s 5.979889014417024s infinite;
  animation: BallSprayer-122 5.673932281523087s 4.5767656002374135s infinite;
}
@-webkit-keyframes BallSprayer-122 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 35%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-122 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 100%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--123 {
  border-radius: 100%;
  left: 90%;
  -webkit-animation: BallSprayer-123 5.99773556207302s 6.879193378017114s infinite;
  animation: BallSprayer-123 5.478646541931664s 0.9228013318376194s infinite;
}
@-webkit-keyframes BallSprayer-123 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 69%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-123 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 93%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--124 {
  border-radius: 100%;
  left: 42%;
  -webkit-animation: BallSprayer-124 5.45886109743822s 4.376895946667185s infinite;
  animation: BallSprayer-124 5.5732388011024545s 8.893401627866988s infinite;
}
@-webkit-keyframes BallSprayer-124 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 66%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-124 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 56%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--125 {
  border-radius: 100%;
  left: 55%;
  -webkit-animation: BallSprayer-125 5.289134282594391s 5.56299601043865s infinite;
  animation: BallSprayer-125 5.8360879506884435s 9.790972037122568s infinite;
}
@-webkit-keyframes BallSprayer-125 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 95%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-125 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 79%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--126 {
  border-radius: 100%;
  left: 84%;
  -webkit-animation: BallSprayer-126 5.459021574946681s 8.85997087510389s infinite;
  animation: BallSprayer-126 5.068916741274791s 4.876054002233953s infinite;
}
@-webkit-keyframes BallSprayer-126 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 20%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-126 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 42%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--127 {
  border-radius: 100%;
  left: 85%;
  -webkit-animation: BallSprayer-127 5.564418995988122s 2.2228913757244584s infinite;
  animation: BallSprayer-127 5.113250578010782s 2.1905137414046094s infinite;
}
@-webkit-keyframes BallSprayer-127 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 3%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-127 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 82%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--128 {
  border-radius: 100%;
  left: 5%;
  -webkit-animation: BallSprayer-128 5.867862454980131s 4.185445003099611s infinite;
  animation: BallSprayer-128 5.077357131413859s 8.69331794974052s infinite;
}
@-webkit-keyframes BallSprayer-128 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 3%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-128 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 60%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--129 {
  border-radius: 100%;
  left: 9%;
  -webkit-animation: BallSprayer-129 5.701901943880507s 5.003204524909497s infinite;
  animation: BallSprayer-129 5.109867833370212s 6.1207556748921395s infinite;
}
@-webkit-keyframes BallSprayer-129 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 78%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-129 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 11%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--130 {
  border-radius: 100%;
  left: 68%;
  -webkit-animation: BallSprayer-130 5.125299327878834s 8.018640298797635s infinite;
  animation: BallSprayer-130 5.508612502573449s 8.472413530515965s infinite;
}
@-webkit-keyframes BallSprayer-130 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 49%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-130 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 43%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--131 {
  border-radius: 100%;
  left: 70%;
  -webkit-animation: BallSprayer-131 5.02651044500476s 1.6199393473811585s infinite;
  animation: BallSprayer-131 5.682650626380867s 3.8129221189274176s infinite;
}
@-webkit-keyframes BallSprayer-131 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 85%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-131 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 95%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--132 {
  border-radius: 100%;
  left: 28%;
  -webkit-animation: BallSprayer-132 5.419671564844398s 5.8008655558259115s infinite;
  animation: BallSprayer-132 5.207670783053823s 5.3553475844451555s infinite;
}
@-webkit-keyframes BallSprayer-132 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 97%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-132 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 64%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--133 {
  border-radius: 100%;
  left: 19%;
  -webkit-animation: BallSprayer-133 5.5575551922798905s 8.877674651528194s infinite;
  animation: BallSprayer-133 5.903485124329896s 4.975618698777236s infinite;
}
@-webkit-keyframes BallSprayer-133 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 82%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-133 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 21%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--134 {
  border-radius: 100%;
  left: 4%;
  -webkit-animation: BallSprayer-134 5.610249067879564s 8.525272978210957s infinite;
  animation: BallSprayer-134 5.949838484256905s 6.056234473900313s infinite;
}
@-webkit-keyframes BallSprayer-134 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 24%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-134 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 46%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--135 {
  border-radius: 100%;
  left: 74%;
  -webkit-animation: BallSprayer-135 5.186023078997891s 8.933398087289335s infinite;
  animation: BallSprayer-135 5.90004994593453s 6.560296589337373s infinite;
}
@-webkit-keyframes BallSprayer-135 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 52%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-135 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 45%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--136 {
  border-radius: 100%;
  left: 7%;
  -webkit-animation: BallSprayer-136 5.509648498543265s 4.345321636848986s infinite;
  animation: BallSprayer-136 5.209303956860211s 8.291687594948701s infinite;
}
@-webkit-keyframes BallSprayer-136 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 46%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-136 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 74%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--137 {
  border-radius: 100%;
  left: 11%;
  -webkit-animation: BallSprayer-137 5.825535185284408s 3.504769284873881s infinite;
  animation: BallSprayer-137 5.346873142819206s 4.625483113884936s infinite;
}
@-webkit-keyframes BallSprayer-137 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 8%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-137 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 25%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--138 {
  border-radius: 100%;
  left: 79%;
  -webkit-animation: BallSprayer-138 5.797205179943981s 7.559505423410795s infinite;
  animation: BallSprayer-138 5.596172402173791s 2.8535605225997185s infinite;
}
@-webkit-keyframes BallSprayer-138 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 47%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-138 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 72%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--139 {
  border-radius: 100%;
  left: 80%;
  -webkit-animation: BallSprayer-139 5.479012979508852s 6.00296635529128s infinite;
  animation: BallSprayer-139 5.1783975132977265s 1.9726413789933006s infinite;
}
@-webkit-keyframes BallSprayer-139 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 47%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-139 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 50%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--140 {
  border-radius: 100%;
  left: 4%;
  -webkit-animation: BallSprayer-140 5.4720750428060985s 5.604962104075273s infinite;
  animation: BallSprayer-140 5.367873866827307s 8.775650495520356s infinite;
}
@-webkit-keyframes BallSprayer-140 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 52%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-140 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 84%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--141 {
  border-radius: 100%;
  left: 67%;
  -webkit-animation: BallSprayer-141 5.640019413693276s 9.686844344987124s infinite;
  animation: BallSprayer-141 5.982670031348942s 5.747067478733752s infinite;
}
@-webkit-keyframes BallSprayer-141 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 100%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-141 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 74%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--142 {
  border-radius: 100%;
  left: 23%;
  -webkit-animation: BallSprayer-142 5.8162179331301935s 4.170245540656068s infinite;
  animation: BallSprayer-142 5.59188332301756s 8.059782027619187s infinite;
}
@-webkit-keyframes BallSprayer-142 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 25%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-142 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 41%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--143 {
  border-radius: 100%;
  left: 50%;
  -webkit-animation: BallSprayer-143 5.100722452320407s 2.6072456212207773s infinite;
  animation: BallSprayer-143 5.563274478199262s 5.882798750352705s infinite;
}
@-webkit-keyframes BallSprayer-143 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 78%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-143 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 64%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--144 {
  border-radius: 100%;
  left: 61%;
  -webkit-animation: BallSprayer-144 5.6977117201325695s 1.0117354315257665s infinite;
  animation: BallSprayer-144 5.873941952714198s 9.10850308575773s infinite;
}
@-webkit-keyframes BallSprayer-144 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 67%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-144 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 83%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--145 {
  border-radius: 100%;
  left: 19%;
  -webkit-animation: BallSprayer-145 5.798383142971941s 5.870271276580262s infinite;
  animation: BallSprayer-145 5.802975844435881s 7.80037952805338s infinite;
}
@-webkit-keyframes BallSprayer-145 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 43%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-145 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 22%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--146 {
  border-radius: 100%;
  left: 7%;
  -webkit-animation: BallSprayer-146 5.007659737064489s 9.033802705334494s infinite;
  animation: BallSprayer-146 5.072071904675216s 4.963455843396469s infinite;
}
@-webkit-keyframes BallSprayer-146 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 84%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-146 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 91%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--147 {
  border-radius: 100%;
  left: 50%;
  -webkit-animation: BallSprayer-147 5.199151213213361s 5.462401829676202s infinite;
  animation: BallSprayer-147 5.387096864268503s 0.7525658399906154s infinite;
}
@-webkit-keyframes BallSprayer-147 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 67%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-147 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 80%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--148 {
  border-radius: 100%;
  left: 54%;
  -webkit-animation: BallSprayer-148 5.864834881940473s 4.240169786472285s infinite;
  animation: BallSprayer-148 5.736606961806836s 5.99452172136785s infinite;
}
@-webkit-keyframes BallSprayer-148 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 45%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-148 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 88%;
    top: 100%;
    opacity: 0;
  }
}
.BallSprayer__ball--149 {
  border-radius: 100%;
  left: 34%;
  -webkit-animation: BallSprayer-149 5.808945799904272s 7.966829917222336s infinite;
  animation: BallSprayer-149 5.641264513508899s 2.372193724747582s infinite;
}
@-webkit-keyframes BallSprayer-149 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 33%;
    top: 100%;
    opacity: 0;
  }
}
@keyframes BallSprayer-149 {
  0% {
    opacity: 1;
    top: 0%;
  }
  45% {
    opacity: 1;
  }
  100% {
    left: 84%;
    top: 100%;
    opacity: 0;
  }
}
