/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CourseReview {
  position: relative;
}
.CourseReview .PageHeader__title {
  font-size: 3.2rem;
  font-weight: 600;
  color: #095d84;
}
@media (max-width: 991px) {
  .CourseReview .PageHeader__title {
    font-size: 3rem;
  }
}
.CourseReview .PageHeader__subtitle {
  color: #095d84;
}
.CourseReview .TabbedSelector .TabbedSelector__tabs {
  margin-top: 0;
}
.CourseReview .TabbedSelector #Tab-minecraftJava {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}
.CourseReview .TabbedSelector #Tab-roblox {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.CourseReview .Tab--selected {
  border: 0;
}
.CourseReview .Tab--theme-formal {
  font-size: 1.4rem;
}
.CourseReview__Wrapper {
  padding: 5rem 10rem;
}
@media (max-width: 1199px) {
  .CourseReview__Wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.CourseReview__FeatureCards {
  text-align: center;
}
